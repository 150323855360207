import FreemiumBannerDesktop_FreemiumBannerDesktopNewLogoComponent from '@wix/thunderbolt-elements/src/components/FreemiumBanner/FreemiumBannerDesktop/viewer/skinComps/FreemiumBannerDesktopNewLogo/FreemiumBannerDesktopNewLogo.skin';


const FreemiumBannerDesktop_FreemiumBannerDesktopNewLogo = {
  component: FreemiumBannerDesktop_FreemiumBannerDesktopNewLogoComponent
};


export const components = {
  ['FreemiumBannerDesktop_FreemiumBannerDesktopNewLogo']: FreemiumBannerDesktop_FreemiumBannerDesktopNewLogo
};

