'use-client';
import * as React from 'react';

import { WixLogoNew } from '../../../../common/assets/logos';
import FreemiumBannerDesktopCommon from '../../FreemiumBannerDesktopCommon';
import type { FreemiumBannerDesktopProps } from '../../../FreemiumBannerDesktop.types';
import style from '../../style/FreemiumBannerDesktopNewLogo.scss';
import { TestIds } from '../../../../common/constants';
import { isWixLogoInText, getTextParts } from '../../utils';

const FreemiumBannerDesktopNewLogo: React.FC<
  FreemiumBannerDesktopProps
> = props => {
  const { translations, direction } = props;

  const { textBeforeLogo, textAfterLogo } = getTextParts(translations);

  return (
    <FreemiumBannerDesktopCommon
      {...props}
      styles={style}
      buttonText={translations.buttonText}
      textBeforeLogo={textBeforeLogo}
      textAfterLogo={textAfterLogo}
      direction={direction}
      className={style.root}
    >
      {isWixLogoInText(translations) && (
        <div data-testid={TestIds.logo} className={style.wixLogoWrapper}>
          <div className={style.wixLogoSeparateLine}>
            <WixLogoNew className={style.logo} />
          </div>
        </div>
      )}
    </FreemiumBannerDesktopCommon>
  );
};
export default FreemiumBannerDesktopNewLogo;
